import AuthModule from '@/store/AuthModule'
import Axios from 'axios'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const EmployeeModule = namespace('EmployeeModule')

@Component({
  name: 'EmployeeListTable',
  filters: {
    truncate (value: string, length: number) {
      return value.length > length
        ? value.substr(0, length) + '...'
        : value
    }
  }
})
export default class EmployeeListTable extends Vue {
    // Config for table for this component
    data: Array<any> = [];
    total = 0;
    loading = false;
    sortField = 'vote_count';
    sortOrder = 'desc';
    defaultSortOrder = 'desc';
    page = 1;
    perPage = 20;

    @EmployeeModule.Getter
    private getIsEmployeeListEmpty!: boolean;

    @EmployeeModule.Getter
    private getIsLoading !: boolean;

    @EmployeeModule.Getter
    private getEmployeeList!: Array<object>;

    @EmployeeModule.Action
    private fetchEmployeeList!: () => Promise<any>;

    created (): void {
      this.fetchEmployeeList()
      console.log(this.getEmployeeList)
    }

    loadAsyncData () {
      const params = [
        'api_key=bb6f51bef07465653c3e553d6ab161a8',
        'language=en-US',
        'include_adult=false',
        'include_video=false',
            `sort_by=${this.sortField}.${this.sortOrder}`,
            `page=${this.page}`
      ].join('&')
      this.loading = true
      Axios.get(`https://api.themoviedb.org/3/discover/movie?${params}`)
        .then(({ data }) => {
          // api.themoviedb.org manage max 1000 pages
          this.data = []
          let currentTotal = data.total_results
          if (data.total_results / this.perPage > 1000) {
            currentTotal = this.perPage * 1000
          }
          this.total = currentTotal
          data.results.forEach((item: any) => {
            item.release_date = item.release_date ? item.release_date.replace(/-/g, '/') : null
            this.data.push(item)
          })
          this.loading = false
        })
        .catch((error) => {
          this.data = []
          this.total = 0
          this.loading = false
          throw error
        })
    }

    //* Handle page-change event
    onPageChange (page: number) {
      this.page = page
      this.loadAsyncData()
    }

    //* Handle sort event
    onSort (field: any, order: any) {
      this.sortField = field
      this.sortOrder = order
      this.loadAsyncData()
    }

    //* Type style in relation to the value
    type (value: string) {
      const number = parseFloat(value)
      if (number < 6) {
        return 'is-danger'
      } else if (number >= 6 && number < 8) {
        return 'is-warning'
      } else if (number >= 8) {
        return 'is-success'
      }
    }
}
