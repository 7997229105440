






















import { Component, Mixins, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'
import Util from '../../../mixins/Util'
import Sidebar from '../../../components/layout/Sidebar.vue'
import EmployeeListTable from '../../../components/dashboard/admin/employee-management/EmployeeListTable.vue'

@Component({
  components: {
    Sidebar,
    EmployeeListTable
  }
})
export default class EmployeeManagement extends Mixins(Util) {
  private beardcrumbs: Array<string> = [];

  beforeMount (): void {
    //* Set the breadcrums for this view
    this.beardcrumbs = this.routeToPathArray(this.$route.path)
  }
}
